import React, { useState, useEffect } from 'react'
import NextNprogress from 'nextjs-progressbar'
import PropTypes from 'prop-types'

export default function VBProgressBar (props) {
  const [config, setConfig] = useState({
    color: '#000',
    startPosition: 0.3,
    stopDelayMs: 200,
    height: 3,
    easing: 'ease',
    speed: 500
  })

  useEffect(() => {
    if (props.config) {
      setConfig(props.config)
    }
  }, [props])
  return (
    <NextNprogress
      {...config}
    />
  )
}

VBProgressBar.propTypes = {
  config: PropTypes.object
}
