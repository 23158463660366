import React, { useEffect, useMemo } from 'react'
import { initTracking } from '../helpers/tracking'
import { useRouter } from 'next/router'
// import Script from 'next/script'

export default function Tracking({ pageComponent }) {
  const router = useRouter()
  const isPageIncludeForm = useMemo(() => {
    return (
      router.pathname === '/ll-bundle-checkout' ||
      router.pathname === '/ll-credit-checkout' ||
      router.pathname === '/ll-paypal-checkout' ||
      router.pathname === '/ll-applepay-checkout' ||
      router.pathname === '/ll-venmo-checkout' ||
      router.pathname === '/sign-up' ||
      router.pathname === '/paypal' ||
      router.pathname === '/credit' ||
      router.pathname === '/apple' ||
      router.pathname === '/venmo' ||
      router.pathname === '/applepay-checkout'
    )
  }, [router.pathname])

  useEffect(() => {
    if (pageComponent) {
      initTracking()
    }
  }, [pageComponent])

  useEffect(() => {
    const script = document.createElement('script')
    window.clickmagick_cmc = {
      vid_info: 'on',
      uid: CLICK_MAGICK_UID,
      hid: CLICK_MAGICK_HID,
      cmc_project: CLICK_MAGICK_CMC_PROJECT
    }
    script.src = 'https://cdn.clkmc.com/cmc.js'
    script.async = true
    script.onload = () => {
      if (isPageIncludeForm && router.query.cmc_ref) {
        const project = window.clickmagick_cmc.cmc_project
        window.clickmagick_cmc.log('a', router.query.cmc_ref, project)
      }
    }
    document.head.appendChild(script)
  }, [isPageIncludeForm, router.pathname, router.query.cmc_ref])

  return <div />
}
